import {required} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
            },
        }
    },

    validations: {
        form: {
            title: {
                required
            },
        }
    },
    computed: {
        ...mapGetters({
            manufacturer:'catalog/manufacturer',
        }),
    },
    watch:{
        manufacturer(e){
            if(e){ 
                this.form.title = e.title;
            }
        }
    }, 
    methods: { 
        closePopup() {
            this.$emit('closePopup')
        },
        sendManufacturer() {
            if(this.manufacturer){
                const payload = {
                    id: this.manufacturer.id,
                    data:this.form
                }
                this.$emit('changeManufacturer', payload)
            }else{
                this.$emit('sendManufacturer', this.form)
            }
        },
        ...mapMutations({
            changeManufacturer: 'catalog/changeManufacturer'
        }),
    },
    destroyed() {
        this.changeManufacturer(null);
    }
}